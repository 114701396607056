import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import h from "../../helpers";
import { SiteCustomizations } from "../../helpers/constants";
import siteCustomization from "../../helpers/siteCustomization";
import { getBrowser, supportedBrowsers } from "../../helpers/userAgent";
import ExternalLoginsListPartial from "./ExternalLoginsListPartial";
import CircularLoading from "../loading/CircularLoading";
import { requestSessionInfo } from "../../actions/sessionActions";
import Snackbar from "../toasts/snackbar";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import { IAppState } from "../../types/stores/index";

const Login: React.FC = () => {
    const dispatch = useDispatch();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showAccountStatus, setShowAccountStatus] = useState("");
    const [submitting, setIsSubmitting] = useState(false);
    const [errorEmail, setErrorEmail] = useState("");
    const [errorPassword, setErrorPassword] = useState("");

    const site = useSelector((state: IAppState) => state.session.site);
    const siteCustomizations = useSelector((state: IAppState) => state.session.customizations);

    useEffect(() => {
        dispatch(requestSessionInfo());
    }, [dispatch]);

    let siteName = "Our site";
    if (site == "AudienceEngine") {
        siteName = siteCustomization(SiteCustomizations.AEname, "Audience Engine", siteCustomizations);
    } else if (site == "DataEngine") {
        siteName = siteCustomization(SiteCustomizations.DEname, "Data Mixology Engine", siteCustomizations);
    }

    let invalidCredentials = "";
    switch (showAccountStatus) {
        case "InvalidCredentials":
            invalidCredentials = "Invalid login credentials, please try again.";
            break;
        case "UserInactive":
            invalidCredentials = "This account is not active. Please contact your administrator for assistance.";
            break;
        case "UserConfigError":
            invalidCredentials =
                "This account needs to be configured. Please contact your administrator for assistance.";
            break;
        case "Lockout":
            invalidCredentials =
                "This account has been locked for security reasons. Please contact your administrator for assistance in unlocking your account";
            break;
        default:
            invalidCredentials = "";
            break;
    }

    const loginErrors = invalidCredentials ? (
        <div className="alert alert-warning-original flow-item-warnings login-errors">
            <Icon style={{ marginRight: "10px", position: "relative", top: "2px" }}>error_outline</Icon>
            {invalidCredentials}
        </div>
    ) : null;

    const browser = getBrowser();

    const handleEmail = e => {
        setEmail(e.target.value);
        emailValidation(e.target.value);
    };

    const handlePasswordChange = e => {
        setPassword(e.target.value);
        passwordValidation(e.target.value);
    };

    const loginEnter = e => {
        e.preventDefault();
        login();
    };

    const loginWithSSO = (providerValue: string) => {
        let form = document.createElement("form");
        let provider = document.createElement("input");
        let forgeryToken = document.createElement("input");
        form.method = "POST";
        form.action = "../../../../Account/ExternalLogin";
        provider.value = providerValue;
        provider.name = "provider";
        forgeryToken.value = (document.getElementsByName("__RequestVerificationToken")[0] as HTMLInputElement).value;
        forgeryToken.name = "__RequestVerificationToken";
        form.appendChild(provider);
        form.appendChild(forgeryToken);
        document.body.appendChild(form);
        form.submit();
    };

    const loginWithUserPass = () => {
        const hasEmailError = emailValidation(email);
        const hasPasswordError = passwordValidation(password);

        if (hasEmailError || hasPasswordError) {
            setIsSubmitting(false);
            return;
        }

        let urlParams = new URLSearchParams(window.location.search);
        let logData = new FormData();
        const url = "/Account/Login";
        let returnUrl: string = "/";
        let token = "";

        logData.append("email", email);
        logData.append("password", password);
        if (urlParams.get("returnUrl") != null) {
            returnUrl = urlParams.get("returnUrl")!;
            if (returnUrl) {
                returnUrl = returnUrl.split("~")[1];
            }
        }

        logData.append("ReturnUrl", returnUrl);
        if (document.getElementsByName("__RequestVerificationToken").length > 0) {
            token = (document.getElementsByName("__RequestVerificationToken")[0] as HTMLInputElement).value;
        }

        fetch(url, {
            credentials: "same-origin",
            method: "POST",
            headers: {
                Accept: "application/json",
                __RequestVerificationToken: token,
            },
            body: logData,
        })
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                const response = data as unknown as { redirecturl: string; message: string };
                if (response) {
                    if (response.redirecturl) {
                        window.location.href = response.redirecturl;
                    } else {
                        setIsSubmitting(false);
                        setShowAccountStatus(response.message);
                    }
                }
            })
            .catch(error => {
                h.error("Error during log in.", error);
                setIsSubmitting(false);
            });
    };

    const login = () => {
        setIsSubmitting(true);

        // Check to see if we should login with SSO or Username/Pass
        fetch("/Account/GetSSOStatus", {
            method: "POST",
            credentials: "same-origin",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email,
            }),
        })
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                const response = data as unknown as { isSSO: boolean; status: string };
                if (response.isSSO) {
                    loginWithSSO(response.status);
                } else {
                    loginWithUserPass();
                }
            })
            .catch(error => {
                h.error("Error getting SSO.", error);
            });
    };

    const passwordValidation = password => {
        let hasError = false;
        if (password == null || password == "") {
            hasError = true;
            setErrorPassword("Password is required.");
        } else {
            hasError = false;
            setErrorPassword("");
        }
        return hasError;
    };

    const emailValidation = email => {
        let hasError = false;
        if (email == null || email == "") {
            hasError = true;
            setErrorEmail("Email address is required.");
        } else if (email.search(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
            hasError = true;
            setErrorEmail("Email address is not valid.");
        } else {
            hasError = false;
            setErrorEmail("");
        }
        return hasError;
    };

    const goToForgotPassword = () => {
        window.location.href = "/account/forgotpassword";
    };

    const submitDisabled = () => submitting || errorEmail != "" || errorPassword != "";

    return (
        <>
            <section className="landing-container login-container">
                <div className="login-left">
                    <div className="inner">
                        <div className="experian-logo" />
                        <h1>{siteName}</h1>
                        {loginErrors}
                        <form
                            onSubmit={e => {
                                if (!submitDisabled()) {
                                    return loginEnter(e);
                                }
                            }}
                            noValidate
                        >
                            <TextField
                                id="login-email"
                                label="Email"
                                type={"email"}
                                value={email}
                                required
                                error={errorEmail != ""}
                                helperText={errorEmail}
                                onChange={handleEmail}
                            />
                            <TextField
                                id="login-password"
                                label="Password"
                                type={"password"}
                                value={password}
                                required
                                error={errorPassword != ""}
                                helperText={errorPassword}
                                onChange={handlePasswordChange}
                            />
                            <div className="forgot-password-link-container">
                                <a
                                    className="forgot-password-link clickable"
                                    onClick={goToForgotPassword}
                                    href="/account/forgotpassword"
                                >
                                    Forgot your password?
                                </a>
                            </div>
                            <Button
                                className="login-button"
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={submitting || errorEmail != "" || errorPassword != ""}
                            >
                                <div className="saving-context">
                                    {submitting ? <CircularLoading size="x-small" color="#FFF" saved={false} /> : null}
                                    Log In
                                </div>
                            </Button>
                        </form>
                        <ExternalLoginsListPartial />
                    </div>
                </div>
                <div className="login-right" />
            </section>
            {browser && !supportedBrowsers.includes(browser.name) && <Snackbar type="browser" />}
        </>
    );
};

export default Login;
